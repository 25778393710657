
import styled from 'styled-components';
import {API} from "../../api";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Button} from "../Button";
import {isMobileDevice} from "../../hooks/mobile/isMobile";


const SHeader = styled.div`
    display: flex;
    width: 100%;
    height: 56px;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    
    .logo {
        ${props => props.isMobile ? "height: 22px;" : "height: 36px;"}
        opacity: 0.7;      
    }
    
    .imageButton img {
        height: 38px;
    }
    
    .imageButton {
        align-self: right;
    }
`;

const SBlock = styled.div`
    display: flex;
    align-items: center;
    margin: 0 ${props => props.isMobile ? "20px" : "30px"};
    ${props => props.isMobile ? "flex: 1 1 0px;" : ""}
`;

const SInline = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > *:not(:last-child) {
        margin-right: 10px;
    }
    
    h3 {
        cursor: pointer;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const SUser = styled(SInline)`
    
    .profilePhoto {
        border-radius: 100%;
        margin-right: ${props => props.isMobile ? "0" : "10px"};
        width: 36px;
        height: 36px;
    }
`;

const api = new API();

export function Header({isMobile = isMobileDevice()}) {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        api.vk.getUser().then((resp) => {
            setUser(resp);
        });
    }, []);

    const doRedirect = () => {
        window.location.href = "/api/auth/vkid/redirect";
    };

    return (
        <SHeader isMobile={isMobile}>
            <SBlock isMobile={isMobile}>
                <SInline>
                    <img className="logo" src="/klzm.png" />
                    {!isMobile && <h3 style={{marginLeft: "15px"}} onClick={() => navigate("/")}>Главная</h3>}
                </SInline>
            </SBlock>
            {isMobile && <SBlock>
                <h3 onClick={() => navigate("/")}>Главная</h3>
            </SBlock>}

            <SBlock style={{justifyContent: "end"}} isMobile={isMobile}>
                {user && <SUser isMobile={isMobile}>
                    <img className="profilePhoto" src={user.photo_200_orig} />
                    {!isMobile && <div>{user.first_name} {user.last_name}</div>}

                </SUser>
                }

                {!user && <SUser isMobile={isMobile}>
                    {isMobile ? (
                        <div className="imageButton flexVertCenter">
                            <img src="/icons/vk_logo.png" onClick={doRedirect} />
                        </div>
                    ) : (
                        <Button size="m" isPrimary={false} onClick={doRedirect}>Войти через VK</Button>
                    )}

                </SUser>
                }
            </SBlock>

        </SHeader>
    )
}
