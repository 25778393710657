import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useRef} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 10px;
    }
    
    > input {
        margin-bottom: 15px;
    }
`;

const api = new API();

export function AuthVkModal({isOpen, setOpen, isMobile=isMobileDevice()}) {
    const loginInput = useRef(null);
    const passwordInput = useRef(null);

    const auth = () => {
        api.authVk(loginInput.current.value, passwordInput.current.value).then((resp) => {
            window.location.reload();
        });
    };

    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Авторизация в Вк Музыке</span></ModalHeader>
        <div>
            <SContent>
                <label>Логин</label>
                <input ref={loginInput} type="text" />
                <label>Пароль</label>
                <input ref={passwordInput} type="password" />
                <span style={{color: 'gray'}}>Мы не храним логин и пароль, данные нужны{!isMobile && <br />} только для получения токена.</span>
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} onClick={auth}><span>Авторизация</span></Button>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
