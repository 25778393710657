import React from "react";
import styled from 'styled-components';
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SBody = styled.div`
    display: flex;
    justify-content: center;
`;

const SContainer = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
`;

const SMobileContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 0 20px;
    flex-direction: column;
    ${props => props.heightOffset === '0px' ? "" : "height: calc(100dvh - 124px - );"}
    overflow-y: auto;
    padding-top: ${props => props.paddingTop};
    
    h1 {
        font-size: 20px;
        font-weight: 500;
    }
    
    h3 {
        font-size: 16px;
        font-weight: 400;
    }
`;


export function Container({children, isMobile = isMobileDevice(), heightOffset='0px', paddingTop='50px'}) {
    return (
        <SBody>
            {isMobile ? <SMobileContainer paddingTop={paddingTop} heightOffset={heightOffset}>{children}</SMobileContainer>  : <SContainer>{children}</SContainer>}
        </SBody>
    )
}
