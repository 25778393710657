import React, {useEffect, useState} from "react";
import {BlockList} from "../../../components/Block/BlockList";
import {Block} from "../../../components/Block";
import {API} from "../../../api";
import {useNavigate} from "react-router";
import {Button} from "../../../components/Button";
import {CreatePlaylistModal} from "../../../modals/CreatePlaylistModal";
import {FloatingContainer} from "../../../components/FloatingContainer";
import {isMobileDevice} from "../../../hooks/mobile/isMobile";

const api = new API();

export function WaitingPage({ room, isMobile=isMobileDevice() }) {
    const [playlists, setPlaylists] = useState([]);
    const [createPlOpen, setCreatePlOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (createPlOpen) return;

        api.getPlaylists(room.hash).then((resp) => {
            setPlaylists(resp);
        })
    }, [createPlOpen]);

    const startPlaying = () => {
        api.startPlaying(room.hash).then((resp) => {
            window.location.reload();
        });
    };

    return <div>
        <div className="inlineRoomName">
            <h1>Плейлисты</h1>
            {isMobile &&
            <Button size="icon" onClick={() => {setCreatePlOpen(true)}}>
                <img src="/icons/plus.png" style={{width:  "16px"}} />
            </Button>
            }
        </div>
        <BlockList>
            {playlists.map((pl) => {
                return <Block key={pl.id} value={pl.id} onClick={(value) => {
                    navigate(`/room/${room.hash}/playlist/${value}`)
                }}><h3>{pl.name}</h3></Block>
            })}
        </BlockList>
        <div>
            {!isMobile &&
                <Button onClick={() => {
                    setCreatePlOpen(true)
                }}>Создать плейлист</Button>
            }
        </div>
        <FloatingContainer>
            <Button size={isMobile ? "l" : "m"} responsive={isMobile} onClick={() => {startPlaying()}}>Начать воспроизведение</Button>
        </FloatingContainer>
        <CreatePlaylistModal isOpen={createPlOpen} setOpen={setCreatePlOpen} room={room} />
    </div>
}
