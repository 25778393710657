import axios from 'axios';
import Cookies from "js-cookie";
import {VkAPI} from "./vk";

export class API {
    baseDomain = 'https://klzmmusicmanager.ru/api';
    vk = new VkAPI();

    getUserId() {
        return Cookies.get("user_id");
    }

    makeRequest(endpoint, method='get', data={}) {
        const url = `${this.baseDomain}/${endpoint}`;

        if (method === "get") {
            return axios.get(url, {
                headers: {
                    "Auth": this.getUserId()
                }
            })
        } else if (method === "post") {
            return axios.post(url, data,{
                headers: {
                    "Auth": this.getUserId()
                }
            })
        }
    }

    wrap(data_key, ...args) {
        return new Promise((resolve, reject) => {
            this.makeRequest(...args).then((resp) => {
                resolve(data_key ? resp.data[data_key] : resp.data);
            })
        })
    }

    getUser() {
        return this.wrap(null, `user/`);
    }

    getRooms() {
        return new Promise((resolve, reject) => {
            this.makeRequest("room/").then((resp) => {
                resolve(resp.data.rooms);
            })
        })
    }

    createRoom(name) {
        return new Promise((resolve, reject) => {
            this.makeRequest("room/?room_name="+name, "post").then((resp) => {
                resolve();
            })
        })
    }

    getRoom(hash) {
        return this.wrap(null, `room/${hash}`)
    }

    getRoomStatus(hash) {
        return this.wrap(null, `room/${hash}/status`)
    }

    getQueue(hash) {
        return this.wrap("tracks", `room/${hash}/queue`)
    }

    upvote(hash, pid, tid) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/track/${tid}/vote`, "post")
    }

    skip(hash) {
        return this.wrap(null, `room/${hash}/skip`, "post")
    }

    searchTrack(platform, query) {
        return this.wrap("tracks", `tracks/${platform}/search?query=${query}`);
    }

    addTrack(hash, platform, track) {
        return this.wrap(null, `room/${hash}/add_track`, "post", {
            platform: platform,
            identifier: track.identifier,
            artist: track.artist,
            title: track.title,
            duration: track.duration,
            cover: track.cover
        });
    }

    nextPlaylist(hash) {
        return this.wrap(null, `room/${hash}/next_playlist`, "post")
    }

    getPlaylists(hash) {
        return this.wrap("playlists", `room/${hash}/playlists`)
    }

    createPlaylist(hash, title) {
        return this.wrap(null, `room/${hash}/playlist?playlist_name=${title}`, "post")
    }

    startPlaying(hash) {
        return this.wrap(null, `room/${hash}/start`, "post")
    }

    getPlaylist(hash, pid) {
        return this.wrap(null, `room/${hash}/playlist/${pid}`)
    }

    importPlaylist(platform, link) {
        return this.wrap("tracks", `tracks/${platform}/playlist/import?link=${encodeURIComponent(link)}`);
    }

    savePlaylist(hash, pid, tracks) {
        return this.wrap(null, `room/${hash}/playlist/${pid}/save`, "post", {"tracks": tracks.map((track) => {return {
            platform: track.platform,
            identifier: track.identifier,
            artist: track.artist,
            title: track.title,
            duration: track.duration,
            cover: track.cover
        }})});
    }

    authVk(login, password) {
        return this.wrap(null, `auth/vk`, "post", {
            login, password
        });
    }

    getYmQr() {
        return this.wrap(null, `auth/ym/qr`);
    }

    authYm(csrf_token, track_id) {
        return this.wrap(null, `auth/ym?csrf_token=${csrf_token}&track_id=${track_id}`);
    }
}
