import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useRef} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 10px;
    }
`;

const api = new API();

export function CreateRoomModal({isOpen, setOpen, isMobile = isMobileDevice()}) {
    const nameInput = useRef(null);

    const createRoom = () => {
        api.createRoom(nameInput.current.value).then(() => {
            setOpen(false);
        })
    };

    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Создать комнату</span></ModalHeader>
        <div>
            <SContent>
                <label>Название комнаты</label>
                <input ref={nameInput} type="text" id="create_room_name" placeholder="Введите название комнаты" />
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} onClick={createRoom}><span>Создать</span></Button>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
