import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import { ThemeProvider } from 'styled-components'
import GlobalStyles from './styles/global'
import { baseTheme } from './styles/theme'
import {HomePage} from "./pages/HomePage";
import {RoomPage} from "./pages/RoomPage";
import {PlaylistPage} from "./pages/PlaylistPage";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage />
    },
    {
        path: '/room/:room_hash',
        element: <RoomPage />
    },
    {
        path: '/room/:room_hash/playlist/:playlist_id',
        element: <PlaylistPage />
    }
]);


root.render(
  <React.StrictMode>
      <ThemeProvider theme={baseTheme}>
          <RouterProvider router={router} />
          <GlobalStyles />
      </ThemeProvider>
  </React.StrictMode>
);
