import React, {useEffect, useState} from "react";
import {API} from "../../api";
import {useNavigate, useParams} from "react-router";
import {Header} from "../../components/Header";
import {Container} from "../../components/Container";
import {BlockSide} from "../../components/Block/BlockSide";
import {Track} from "../../components/Track";
import {Block} from "../../components/Block";
import {Button} from "../../components/Button";
import {styled} from "styled-components";
import {AddTrackModal} from "../../modals/AddTrackModal";
import {ImportPlaylistModal} from "../../modals/ImportPlaylistModal";
import {isMobileDevice} from "../../hooks/mobile/isMobile";
import {Bar} from "../../components/Bar";

const api = new API();

const SRow = styled.div`
    display: flex;
    flex-direction: row;
    
    > div {
        margin-right: 20px;
    }
`;

export function PlaylistPage({isMobile = isMobileDevice()}) {
    const [playlist, setPlaylist] = useState({});
    const [tracks, setTracks] = useState([]);
    const [addTrackOpen, setAddTrackOpen] = useState(false);
    const [importPlaylistOpen, setImportPlaylistOpen] = useState(false);
    const [newTrack, setNewTrack] = useState(null);
    const {room_hash, playlist_id} = useParams();
    const navigate = useNavigate();

    const importCallback = (newTracks, do_clear) => {
        let updTracks = [];
        if (!do_clear) updTracks = [...tracks];
        updTracks = [...updTracks, ...newTracks];
        setTracks(updTracks);
    };

    const deleteTrack = (tid) => {
        setTracks(tracks.filter((t) => t.identifier !== tid));
    };

    useEffect(() => {
       api.getPlaylist(room_hash, playlist_id).then((resp) => {
           setPlaylist(resp);
           setTracks(resp.tracks);
       })
    }, []);

    useEffect(() => {
        if (newTrack) {
            let newTracks = [...tracks];
            newTracks.push(newTrack);
            setTracks(newTracks);
        }

    }, [newTrack]);

    const savePlaylist = () => {
        api.savePlaylist(room_hash, playlist_id, tracks).then((resp) => {
            navigate("/room/"+room_hash);
        });
    };

    return <div>
            <Header  />
            <Container paddingTop={'0px'}>
                <h1 style={{whiteSpace: 'nowrap', textOverflow: "ellipsis", overflowX: "hidden"}}>Плейлист {playlist.name}</h1>
                <Block height={isMobile ? "100dvh - 220px" : "100dvh - 350px"} smallPadding={isMobile}>
                    <BlockSide col={true}>
                        {tracks.map((tr) => {
                            return <Track key={tr.identifier} artist={tr.artist} title={tr.title} tid={tr.identifier} cover={tr.cover} identifier={tr.identifier}  onDelete={(tid) => {
                                deleteTrack(tid);
                            }} />
                        })}
                    </BlockSide>
                </Block>
                {isMobile ? (
                    <Bar buttons={[
                        {text: "Импорт плейлиста", icon: "/icons/imp_pl.svg", onClick: () => {setImportPlaylistOpen(true)}},
                        {text: "Сохранить", icon: "/icons/save.svg", onClick: savePlaylist},
                        {text: "Добавить трек", icon: "/icons/plus_gray.svg", onClick: () => {setAddTrackOpen(true)}},
                    ]} />
                ) : (
                    <SRow>
                        <Button size="m" onClick={() => {savePlaylist()}}>Сохранить</Button>
                        <Button size="m" isPrimary={false} onClick={() => {setImportPlaylistOpen(true)}}>Импортировать плейлист</Button>
                        <Button size="m" isPrimary={false} onClick={() => {setAddTrackOpen(true)}}>Добавить трек</Button>
                    </SRow>
                )}

                <AddTrackModal setNewTrack={setNewTrack} room={{hash: room_hash}} setOpen={setAddTrackOpen} isOpen={addTrackOpen} />
                <ImportPlaylistModal setOpen={setImportPlaylistOpen} isOpen={importPlaylistOpen} callback={importCallback} />
            </Container>
        </div>
}
