import {BaseModal, ModalFooter, ModalHeader} from "../BaseModal";
import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import {Button} from "../../components/Button";
import {API} from "../../api";
import {PlatformToggle} from "../../components/PlatformToggle";
import {isMobileDevice} from "../../hooks/mobile/isMobile";

const SContent =  styled.div`
    display: flex;
    flex-direction: column;
    
    label {
        margin-bottom: 10px;
    }
    
    > div.pad {
        margin-top: 16px;
    }
    
    .search {
        display: flex;
    }
    
    input {
        
        width: 400px;
    }
    
    
    
`;

const api = new API();

export function ImportPlaylistModal({isOpen, setOpen, callback, isMobile=isMobileDevice()}) {
    const [user, setUser] = useState();
    const [platform, setPlatform] = useState();
    const searchInput = useRef(null);
    const clearInput = useRef(null);

    useEffect(() => {
        api.getUser().then((resp) => {
            setUser(resp);
        })
    }, []);

    const importPlaylist = () => {
        api.importPlaylist(platform, searchInput.current.value).then((resp) => {
            callback(resp, clearInput.current.checked);
            setOpen(false);
            searchInput.current.value = "";
        });
    };


    return <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <ModalHeader><span>Импортировать плейлист</span></ModalHeader>
        <div>
            <SContent>
                {user && <PlatformToggle setPlatform={setPlatform} hasVk={user.has_vk_music} hasYm={user.has_ym_music} />}
                <div className="search pad">
                    <input ref={searchInput} type="text" id="import_playlist_input" placeholder="Вставьте ссылку на плейлист" />
                </div>
                <div>
                    <label for="clear-checkbox" className="checkbox-container">
                        Очистить плейлист перед импортом
                        <input type="checkbox" id="clear-checkbox" defaultChecked={true} ref={clearInput}/>
                        <span className="checkmark"></span>
                    </label>

                </div>
            </SContent>
        </div>
        <ModalFooter>
            <Button size="s" responsive={isMobile} onClick={importPlaylist}><span>Импорт</span></Button>
            <Button size="s" responsive={isMobile} isRich={false} isPrimary={false} onClick={() => setOpen(false)}>Отмена</Button>
        </ModalFooter>

    </BaseModal>
}
