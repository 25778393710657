import Cookies from "js-cookie"
import {useCallback, useEffect, useState} from "react";
import {API} from "../../api";

const api = new API();

export function useAuth() {
    const [hasAuth, setHasAuth] = useState(false);
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (hasAuth) {
            api.getUser().then((resp) => {
                setUser(resp);
            });
        }
    }, [hasAuth]);

    useEffect(() => {
        const cookie = Cookies.get("user_id");
        if (cookie) {
            setHasAuth(true);
            setUserId(cookie)
        } else {
            window.location.href = "/api/auth/vkid/redirect";
        }
    }, []);

    const updateCookie = useCallback(
        (name, newValue, options) => {
            Cookies.set(name, newValue, options);
        },
        []
    );

    return {hasAuth, userId, updateCookie, user}
}
