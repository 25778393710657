import React, {useEffect, useState} from "react";
import styled from "styled-components";

const SCooldowns = styled.div`
    display: flex;
    flex-direction: column;
    
    h3 {
        margin-top: 10px;
    }
`;

const SMobileCooldown = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.bg};
    
    border-radius: ${({ theme }) => theme.blocks.radius};
    border: 1px solid ${({ theme }) => theme.colors.border};
    width: calc(100vw - 160px);
    
    h3, h4 {
        font-size: 12px;
        font-weight: 400; 
    }
    
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 5px 8px;
        flex: 1 1 0px;
    }
    
    > div:not(:last-child) {
        
        border-right: 1px solid ${({ theme }) => theme.colors.border};
    }
`;

export function Cooldowns({upvote_at, skip_at, isMobile=false}) {
    const [voteCooldown, setVoteCooldown] = useState("00:00");
    const [skipCooldown, setSkipCooldown] = useState("00:00");

    function update() {
        setVoteCooldown(getCooldown(upvote_at));
        setSkipCooldown(getCooldown(skip_at));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            update();
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [upvote_at, skip_at]);

    const getCooldown = (ts) => {
        if (!ts) return "00:00";
        let d = new Date();
        d = new Date(ts*1000 + d.getTimezoneOffset()*60*-1000);
        const cd = new Date();
        const delta = d - cd;
        if (delta <= 0) return "00:00";
        let cooldown = new Date(delta).toISOString().substring(14, 19);
        return cooldown;

    };

    useEffect(() => {
        update();
    }, []);


    return isMobile ? (
        <SMobileCooldown>
            <div>
                <h3 style={{marginTop: '0'}}>Кулдаун скипа</h3>
                <h4>{skipCooldown}</h4>
            </div>
            <div>
                <h3>Кулдаун трека</h3>
                <h4>{voteCooldown}</h4>
            </div>
        </SMobileCooldown>
    ) : <SCooldowns>
        <h3 style={{marginTop: '0'}}>Кулдаун пропуска</h3>
        <h4>{skipCooldown}</h4>
        <h3>Кулдаун трека</h3>
        <h4>{voteCooldown}</h4>
    </SCooldowns>
}
